@import "variables";

.react-datePicker-lg {
  .react-datepicker-popper {
    z-index: 10000 !important;
  }

  .react-datepicker__triangle {
    &::after {
      display: none;
    }

    &::before {
      display: none;
    }
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: 0 !important;
  }

  .react-datepicker-popper[data-placement^=bottom] {
    width: 100%;
  }

  .item-selected {
    background: $primary;
    color: white !important;
  }

  .item-selected-between {
    background: $primary-light;
    color: white !important;
  }

  .item-secondary-selected {
    background: $success;
    color: white !important;
  }

  .item-excluded {
    background: $light;
    color: gray;
    cursor: not-allowed !important;
  }

  .react-datepicker {
    width: 100%;
    border: 1px solid $light !important;
    box-shadow: 0 .5rem 1rem rgba($black, .15);

    .react-datepicker__month-container {
      width: 100% !important;

    }

    .react-datepicker__day-names {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .react-datepicker-time__input-container {
      display: flex;
      width: 85%;
      padding-right: 30px;
      overflow: auto;
    }

    .react-datepicker__day {
      width: 2.5rem;
      height: 2.5rem;
      margin: .1rem;
      padding-top: 6px;
      border-radius: 50%;
      box-shadow: 0 .1rem .3rem rgba($black, .15);
    }

    @media (max-width: 1200px) {
      max-width: 400px;
    }

    @media (min-width: 1200px) {
      width: 600px !important;
      .react-datepicker__day-name {
        width: 3.5rem;
      }
      .react-datepicker__day {
        width: 4rem;
        padding-top: 15px;
        margin: 4px;
        height: 4rem;
      }
    }


    .react-datepicker__time-list-item {
      list-style: none;
      color: gray;
      padding: 3px 6px 3px 6px;
      border: 1px solid $light;
      border-radius: 4px;
      margin-right: 1.5px;
      margin-left: 1.5px;
      cursor: pointer;
    }

    .react-datepicker__time-container {
      border-left: none;
    }

    .react-datepicker__current-month {
      margin-top: 5px;
    }

    .react-datepicker__navigation {
      margin-top: 5px;
    }

    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 20%;
    }
  }

}