.task-detail{
  &-wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 15px;
    border-top: 1px solid #f2f2f2;
    min-height: calc(60vh - 10px);

    grid-template-areas: "task-detail-description task-detail-tab"
                          "task-detail-description task-detail-tab";

    .task-detail-description{
      grid-area: task-detail-description;
      border-right: 1px solid #f2f2f2;

      @media (max-width: 998px) {
        border: none;
      }
    }

    @media (max-width: 1236px) {
      grid-template-columns: 1fr;
      grid-template-areas: "task-detail-description"
                            "task-detail-tab";
    }
  }

  &-form {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(265px, 1fr) );
    align-items: start;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
  }

  &-info {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    grid-row-gap: 8px;
    grid-column-gap: 5px;
  }
}
