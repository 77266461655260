.timeline {
  position: relative;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  left: 1rem;
  height: 100%;
  border-right: 2px solid #e9ecef;
}


.timeline-one-side:before {
  left: 1rem;
}

.timeline-one-side .timeline-step {
  left: 1rem;
}

.timeline-one-side .timeline-content {
  width: auto;
}

@media (min-width: 992px) {
  .timeline-one-side .timeline-content {
    max-width: 50rem;
  }
}

.timeline-one-side .timeline-block:nth-child(even) .timeline-content {
  float: none;
}


.timeline-block {
  position: relative;
  margin: 2em 0;
}

.timeline-block:after {
  content: "";
  display: table;
  clear: both;
}

.timeline-block:first-child {
  margin-top: 0;
}

.timeline-block:last-child {
  margin-bottom: 0;
}

.timeline-step {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  left: 0;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  text-align: center;
  transform: translateX(-50%);
  font-size: 1rem;
  font-weight: 600;
  z-index: 1;
}

.timeline-step svg, .timeline-step i {
  line-height: 1.4;
}

.timeline-step-icon {
  background: #fff;
  border: 2px solid #e9ecef;
}

.timeline-step-xs {
  width: 17px;
  height: 17px;
  font-size: .75rem;
}

.timeline-step-sm {
  width: 23px;
  height: 23px;
  font-size: .75rem;
}

.timeline-step-lg {
  width: 47px;
  height: 47px;
  font-size: 1.75rem;
}

.timeline-content {
  position: relative;
  margin-left: 60px;
  padding-top: .5rem;
  top: -6px;
}

.timeline-content:after {
  content: "";
  display: table;
  clear: both;
}

.timeline-body {
  padding: 1.5rem;
}

@media (min-width: 992px) {
  .timeline:before {
    left: 50%;
    margin-left: -2px;
  }
  .timeline-step {
    left: 50%;
  }
  .timeline-content {
    width: 38%;
  }
  .timeline-body {
    padding: 1.5rem;
  }
  .timeline-block:nth-child(even) .timeline-content {
    float: right;
  }
  [data-timeline-axis-color="primary"]:before {
    border-color: #5e72e4;
  }
  [data-timeline-axis-color="secondary"]:before {
    border-color: #f7fafc;
  }
  [data-timeline-axis-color="success"]:before {
    border-color: #2dce89;
  }
  [data-timeline-axis-color="info"]:before {
    border-color: #11cdef;
  }
  [data-timeline-axis-color="warning"]:before {
    border-color: #fb6340;
  }
  [data-timeline-axis-color="danger"]:before {
    border-color: #f5365c;
  }
  [data-timeline-axis-color="light"]:before {
    border-color: #adb5bd;
  }
  [data-timeline-axis-color="dark"]:before {
    border-color: #212529;
  }
  [data-timeline-axis-color="default"]:before {
    border-color: #172b4d;
  }
  [data-timeline-axis-color="white"]:before {
    border-color: #fff;
  }
  [data-timeline-axis-color="neutral"]:before {
    border-color: #fff;
  }
  [data-timeline-axis-color="darker"]:before {
    border-color: black;
  }
}

.timeline-one-side:before {
  left: 1rem;
}

.timeline-one-side .timeline-step {
  left: 1rem;
}

.timeline-one-side .timeline-content {
  width: auto;
}

@media (min-width: 992px) {
  .timeline-one-side .timeline-content {
    max-width: 30rem;
  }
}

.timeline-one-side .timeline-block:nth-child(even) .timeline-content {
  float: none;
}


[data-timeline-axis-style="dashed"] {
  &:before {
    border-right-style: dashed !important;
  }
}

[data-timeline-axis-style="dotted"] {
  &:before {
    border-right-style: dotted !important;
  }
}


[data-timeline-axis-style="dashed"]:before {
  border-right-style: dashed !important; }

[data-timeline-axis-style="dotted"]:before {
  border-right-style: dotted !important; }
