.profile-container {
  display: grid;
  grid-template-columns: 18em 1fr;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }


}

.grid {
  &-full {
    &-column {
      grid-column: 1 / -1;

      &-xxl {
        @media (max-width: 1400px) {
          grid-column: 1 / -1;
        }
      }
    }
  }
}


@mixin grid-1fr {
  display: grid;
  grid-template-columns: 1fr;
}

.multifile {
  &-container {
    display: grid;
    grid-template-columns: 20em 1fr;
    column-gap: 10px;

    @media (max-width: 1340px) and (min-width: 569px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 568px) {
      @include grid-1fr;
      //grid-template-columns: 1fr 1fr;
    }
  }

  &-list {
    &-item {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }

  }
}
