.daterangepicker {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $zindex-dropdown;
  padding: 0;
  margin: $dropdown-spacer 0 0;
  font-size: $font-size-base;
  color: $body-color;
  text-align: left;
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  @include border-radius($dropdown-border-radius);
  box-shadow: $box-shadow-default;
  display: none;
}

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: $dropdown-border-color;
  content: '';
}

.daterangepicker:before {
  top: -10px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid $dropdown-border-color;
}

.daterangepicker:after {
  top: -9px;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $white;
  border-left: 10px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 9px;
}

.daterangepicker.drop-up {
  margin-top: -11px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -11px;
  border-bottom: initial;
  border-top: 10px solid $dropdown-border-color;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -10px;
  border-bottom: initial;
  border-top: 10px solid $white;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 0;
}

.daterangepicker .drp-calendar.right {
  padding: 8px 8px 8px 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: $white;
  border: solid $gray-800;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 4px;
}

.daterangepicker .calendar-table .next:hover,
.daterangepicker .calendar-table .prev:hover {
  span {
    border: solid $white;
    border-width: 0 2px 2px 0;
  }
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 34px;
  width: 36px;
  height: 34px;
  line-height: 34px;
  border-radius: $border-radius;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid $white;
  border-radius: $border-radius;
  background-color: $white;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: $primary;
  border-color: transparent;
  color: $white;
}

.daterangepicker td.week, .daterangepicker th.week {
  color: #ccc;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: $white;
  border-color: transparent;
  color: $gray-800;
}

.daterangepicker td.in-range {
  background-color: $gray-200;
  border-color: transparent;
  color: $gray-600;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: $border-radius 0 0 $border-radius;
}

.daterangepicker td.end-date {
  border-radius: 0 $border-radius $border-radius 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: $border-radius;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: $primary;
  border-color: transparent;
  color: $white;
  @include border-radius($border-radius);
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: $gray-600;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid $gray-300;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 1px solid $gray-300;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
  padding: 2px 4px;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  padding: 6px 12px;
  cursor: pointer;
  margin: 2px 0;
  @include border-radius($border-radius);
  color: $dropdown-link-color;
  background-color: transparent;
  cursor: pointer;

}

.daterangepicker .ranges li:hover {
  background-color: $dropdown-link-hover-bg;
  color: $dropdown-link-hover-color;
}

.daterangepicker .ranges li.active {
  background-color: $primary;
  color: $white;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }
  .daterangepicker .ranges ul {
    width: 140px;
  }
  .daterangepicker.single .ranges ul {
    width: 100%;
  }
  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }
  .daterangepicker.single.ltr .ranges, .daterangepicker.single.ltr .drp-calendar {
    float: left;
  }
  .daterangepicker.single.rtl .ranges, .daterangepicker.single.rtl .drp-calendar {
    float: right;
  }
  .daterangepicker.ltr {
    direction: ltr;
    text-align: left;
  }
  .daterangepicker.ltr .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }
  .daterangepicker.ltr .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker.ltr .drp-calendar.right {
    margin-left: 0;
  }
  .daterangepicker.ltr .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker.ltr .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }
  .daterangepicker.ltr .ranges, .daterangepicker.ltr .drp-calendar {
    float: left;
  }
  .daterangepicker.rtl {
    direction: rtl;
    text-align: right;
  }
  .daterangepicker.rtl .drp-calendar.left {
    clear: right;
    margin-left: 0;
  }
  .daterangepicker.rtl .drp-calendar.left .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker.rtl .drp-calendar.right {
    margin-right: 0;
  }
  .daterangepicker.rtl .drp-calendar.right .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker.rtl .drp-calendar.left .calendar-table {
    padding-left: 12px;
  }
  .daterangepicker.rtl .ranges, .daterangepicker.rtl .drp-calendar {
    text-align: right;
    float: right;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }
  .daterangepicker.ltr .ranges {
    float: left;
  }
  .daterangepicker.rtl .ranges {
    float: right;
  }
  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}