@import "cms_style";
@import "variables";
@import "list";
@import "timeline";
@import "../components/TaskComponent/taskComponent";
@import "autoSuggest";
@import "table";
@import "app";
@import "linear-icons";
@import "~ionicons-npm/css/ionicons.css";
@import "squares-animated";
@import "~pixeden-stroke-7-icon/pe-icon-7-stroke/dist/pe-icon-7-stroke.css";
@import "scss/base";
@import "helpers";
@import "text";
@import "grid";
@import "~react-datepicker/dist/react-datepicker.css";
@import "fields";

//@import "~bootstrap/scss/bootstrap";

.ant-menu-submenu-title {
  display: flex;
  align-items: center;
}

::-webkit-scrollbar-thumb {
  background: $gray;
  border-radius: 8px;

}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}


.date-picker-component {
  .SingleDatePickerInput, .DateRangePickerInput {
    border-radius: 3px !important;
    border: 1px solid rgba(94, 94, 94, 0.14) !important;
    height: calc(1.5em + .75rem + 10px) !important;
  }

  .DateInput {
    border-bottom: 0 solid transparent !important;
    height: 100%;

    .DateInput_input {
      height: 100%;
    }
  }
}


.animation_pulse {
  margin: 0 auto;
  border: 2px solid $gray-300;
  box-shadow: inset 0 -3em 3em rgba(255, 255, 255, 0.1),
  0 0 0 2px rgb(255, 255, 255),
  0.3em 0.3em 1em rgba(0, 0, 0, 0.3) !important;
  animation-name: stretch;
  animation-duration: 2.0s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;

  &:hover {
    animation-duration: 10.0s;
  }


}

.animation_pulse_rhombus {
  margin: 0 auto;
  border: 2px solid $gray-300;
  box-shadow: inset 0 -3em 3em rgba(255, 255, 255, 0.1),
  0 0 0 2px rgb(255, 255, 255),
  0.3em 0.3em 1em rgba(0, 0, 0, 0.3) !important;
  animation-name: rotateNode;
  animation-duration: 2.0s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;

  &:hover {
    animation-duration: 10.0s;
  }

}

@keyframes rotateNode {
  0% {
    transform: scale(1) rotate(45deg);
  }

  100% {
    transform: scale(.9) rotate(45deg);
  }
}

@keyframes stretch {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(.9);
  }
}

.dot-carousel {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: gray;
  color: gray;
  box-shadow: 9984px 0 0 0 gray, 9999px 0 0 0 gray, 10014px 0 0 0 gray;
  animation: dot-carousel 1.5s infinite linear;
}

@keyframes dot-carousel {
  0% {
    box-shadow: 9984px 0 0 -1px gray, 9999px 0 0 1px gray, 10014px 0 0 -1px gray;
  }
  50% {
    box-shadow: 10014px 0 0 -1px gray, 9984px 0 0 -1px gray, 9999px 0 0 1px gray;
  }
  100% {
    box-shadow: 9999px 0 0 1px gray, 10014px 0 0 -1px gray, 9984px 0 0 -1px gray;
  }
}

.no-focused {
  &:focus {
    outline: none;
  }
}

.bg-red {
  background-color: $red !important;
}

.text-red {
  color: $red !important;
}

.text-red {
  color: $red !important;
}