.accordion-wrapper {
  @include border-radius($border-radius);
  border: $gray-200 solid 1px;

  & > .card {
    box-shadow: 0 0 0 0 transparent;

    & > .card-header {
      padding: 2rem;
      height: auto;

      .btn:active,
      .btn:focus,
      .btn:hover {
        text-decoration: none;
      }

      .form-heading {
        p {
          margin: 0;
        }
      }
    }

    .collapse {
      border-bottom: transparent solid 1px;

      &.show {
        border-bottom-color: $gray-200;
      }
    }
  }
}


.collapse-custom {
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;

  &-out {
    max-height: 0 !important;
  }

  &-in {
    max-height: 1000px !important;
    overflow: auto;
  }
}
