/* styles.module.css */

.containerLogin {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f8e1f4 0%, #e6e5f5 50%, #e1eaf8 100%);
  overflow: hidden;
  position: relative;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.gradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(248, 225, 244, 0.7) 0%, rgba(230, 229, 245, 0.7) 50%, rgba(225, 234, 248, 0.7) 100%);
  z-index: 1;
}
.backgroundVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  object-fit: cover;
}

.cardLogin {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  padding: 2.5rem;
  padding-top: 6.2rem;
  width: 29.5rem;
  position: relative;
  z-index: 10;
  /*height: 512px;*/
}

.logoContainerLogin {
  position: absolute;
  top: -3rem;
  left: 50%;
  transform: translateX(-50%);
}

.logoLogin {
  width: 100px;
  height: 100px;
  border-radius: 50%;

  background-size: cover !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.formLogin {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inputGroupLogin {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.labelLogin {
  color: #6b46c1;
  font-weight: 500;
  font-size: 16px;
}

.inputWrapperLogin {
  position: relative;
}

.inputIconLogin {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
}

.inputLogin {
  width: 100%;
  padding: 0.5rem 0.75rem;
  padding-left: 2.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 1rem;
  height: 42px;
}

.inputLogin:focus {
  outline: none;
  border-color: #8b5cf6;
  box-shadow: 0 0 0 3px rgba(139, 92, 246, 0.1);
}

.checkboxContainerLogin {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkboxLogin {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid #d1d5db;
  border-radius: 50%;
}

.checkboxLogin:checked {
  background-color: #8b5cf6;
  border-color: #8b5cf6;
}

.checkboxLabelLogin {
  font-size: 0.875rem;
  color: #6b46c1;
}

.submitButtonLogin {
  width: 100%;
  padding: 0.5rem 1rem;
  background: linear-gradient(135deg, #ff3366, #9933ff);
  color: white;
  border: none;
  height: 40px;
  border-radius: 0.375rem;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submitButtonLogin:hover {
  transform: scale(1.05);
}

.submitButtonLogin:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(153, 51, 255, 0.5);
}

.submitButtonLogin:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.spinnerLogin {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #ffffff;
  border-top: 2px solid transparent;
  border-radius: 50%;
  margin: 0 auto;
}

.forgotPasswordLogin {
  text-align: center;
  margin-top: 1rem;
  font-size: 16px;
}

.forgotPasswordLinkLogin {
  color: #6b46c1;
  text-decoration: none;
  font-size: 1.1rem;
  cursor: pointer;
}

.forgotPasswordLinkLogin:hover {
  text-decoration: underline;
}

.dividerLogin {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 1.5rem 0;
}

.dividerLogin::before,
.dividerLogin::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #d1d5db;
}

.dividerTextLogin {
  padding: 0 0.5rem;
  color: #6b7280;
  font-size: 0.875rem;
}

.socialButtonsLogin {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.socialButtonLogin {
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  background-color: white;
  color: #6b7280;
  cursor: pointer;
  width: 34px;
  height: 34px;
  transition: background-color 0.3s ease;
}

.socialButtonLogin:hover {
  background-color: #f3f4f6;
}

.socialIconLogin {
  width: 1rem;
  height: 1rem;
}
