/*! nouislider - 14.6.4 - 3/18/2021 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
 .noUi-target,
 .noUi-target * {
   -webkit-touch-callout: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   -webkit-user-select: none;
   -ms-touch-action: none;
   touch-action: none;
   -ms-user-select: none;
   -moz-user-select: none;
   user-select: none;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }
 .noUi-target {
   position: relative;
 }
 .noUi-base,
 .noUi-connects {
   width: 100%;
   height: 100%;
   position: relative;
   z-index: 1;
 }
 /* Wrapper for all connect elements.
  */
 .noUi-connects {
   overflow: hidden;
   z-index: 0;
 }
 .noUi-connect,
 .noUi-origin {
   will-change: transform;
   position: absolute;
   z-index: 1;
   top: 0;
   right: 0;
   -ms-transform-origin: 0 0;
   -webkit-transform-origin: 0 0;
   -webkit-transform-style: preserve-3d;
   transform-origin: 0 0;
   transform-style: flat;
 }
 .noUi-connect {
   height: 100%;
   width: 100%;
 }
 .noUi-origin {
   height: 10%;
   width: 10%;
 }
 /* Offset direction
  */
 .noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
   left: 0;
   right: auto;
 }
 /* Give origins 0 height/width so they don't interfere with clicking the
  * connect elements.
  */
 .noUi-vertical .noUi-origin {
   width: 0;
 }
 .noUi-horizontal .noUi-origin {
   height: 0;
 }
 .noUi-handle {
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
   position: absolute;
   outline: none !important;
   cursor: pointer;
 }
 .noUi-touch-area {
   height: 100%;
   width: 100%;
 }
 .noUi-state-tap .noUi-connect,
 .noUi-state-tap .noUi-origin {
   -webkit-transition: transform 0.3s;
   transition: transform 0.3s;
 }
 .noUi-state-drag * {
   cursor: inherit !important;
 }
 /* Slider size and handle placement;
  */
 .noUi-horizontal {
   height: 4px;
 }
 .noUi-horizontal .noUi-handle {
   width: 16px;
   height: 16px;
   right: -6px;
   top: -6px;
 }
 .noUi-vertical {
   width: 4px;
 }
 .noUi-vertical .noUi-handle {
  width: 16px;
  height: 16px;
  right: -6px;
  top: -6px;
 }
 .noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
   left: -17px;
   right: auto;
 }
 /* Styling;
  * Giving the connect element a border radius causes issues with using transform: scale
  */
 .noUi-target {
   background: $gray-200;
   @include border-radius($border-radius);
 }
 .noUi-connects {
  @include border-radius($border-radius);
 }
 .noUi-connect {
   background: $primary;
 }
 /* Handles and cursors;
  */
 .noUi-draggable {
   cursor: ew-resize;
 }
 .noUi-vertical .noUi-draggable {
   cursor: ns-resize;
 }
 .noUi-handle {
   border: 2px solid $primary;
   @include border-radius(100%);
   background: $white;
   cursor: default;
   transition: box-shadow 200ms;
 }
 .noUi-active {
  border-color: darken($primary, 15%);
  box-shadow: 0 0.46875rem 2.1875rem rgba($primary, .8),
  0 0.125rem 0.5875rem rgba($primary, .9);
 }
 /* Handle stripes;
  */
 .noUi-handle:before,
 .noUi-handle:after {
   content: "";
   display: block;
   position: absolute;
   height: 14px;
   width: 1px;
   background: #E8E7E6;
   left: 14px;
   top: 6px;
 }
 .noUi-handle:after {
   left: 17px;
 }
 .noUi-vertical .noUi-handle:before,
 .noUi-vertical .noUi-handle:after {
   width: 14px;
   height: 1px;
   left: 6px;
   top: 14px;
 }
 .noUi-vertical .noUi-handle:after {
   top: 17px;
 }
 /* Disabled state;
  */
 [disabled] .noUi-connect {
   background: $gray-200;
 }
 [disabled].noUi-target,
 [disabled].noUi-handle,
 [disabled] .noUi-handle {
   cursor: not-allowed;
 }
 /* Base;
  *
  */
 .noUi-pips,
 .noUi-pips * {
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }
 .noUi-pips {
   position: absolute;
   color: $gray-300;
 }
 /* Values;
  *
  */
 .noUi-value {
   position: absolute;
   white-space: nowrap;
   text-align: center;
 }
 .noUi-value-sub {
   color: $gray-600;
   font-size: 10px;
 }
 /* Markings;
  *
  */
 .noUi-marker {
   position: absolute;
   background: $gray-400;
 }
 .noUi-marker-sub {
   background: $gray-300;
 }
 .noUi-marker-large {
   background: $gray-300;
 }
 /* Horizontal layout;
  *
  */
 .noUi-pips-horizontal {
   padding: 10px 0;
   height: 80px;
   top: 100%;
   left: 0;
   width: 100%;
 }
 .noUi-value-horizontal {
   -webkit-transform: translate(-50%, 50%);
   transform: translate(-50%, 50%);
 }
 .noUi-rtl .noUi-value-horizontal {
   -webkit-transform: translate(50%, 50%);
   transform: translate(50%, 50%);
 }
 .noUi-marker-horizontal.noUi-marker {
   margin-left: -1px;
   width: 2px;
   height: 5px;
 }
 .noUi-marker-horizontal.noUi-marker-sub {
   height: 10px;
 }
 .noUi-marker-horizontal.noUi-marker-large {
   height: 15px;
 }
 /* Vertical layout;
  *
  */
 .noUi-pips-vertical {
   padding: 0 10px;
   height: 100%;
   top: 0;
   left: 100%;
 }
 .noUi-value-vertical {
   -webkit-transform: translate(0, -50%);
   transform: translate(0, -50%);
   padding-left: 25px;
 }
 .noUi-rtl .noUi-value-vertical {
   -webkit-transform: translate(0, 50%);
   transform: translate(0, 50%);
 }
 .noUi-marker-vertical.noUi-marker {
   width: 5px;
   height: 2px;
   margin-top: -1px;
 }
 .noUi-marker-vertical.noUi-marker-sub {
   width: 10px;
 }
 .noUi-marker-vertical.noUi-marker-large {
   width: 15px;
 }
 .noUi-tooltip {
   display: block;
   padding: 6px 0;
   color: $white;
   text-align: center;
   text-decoration: none;
   background-color: $gray-800;
   border-radius: 6px;
   box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
   min-width: 80px;
   font-weight: bold;
   white-space: nowrap;
   position: absolute;
   &::after {
     position: absolute;
     content: '';
     border-color: transparent;
     border-style: solid;
     bottom: -5px;
     margin-left: -5px;
     border-width: 5px 5px 0;
     left: 50%;
     border-top-color: $gray-800;
   }
 }
 .noUi-horizontal .noUi-tooltip {
   -webkit-transform: translate(-50%, 0);
   transform: translate(-50%, 0);
   left: 50%;
   bottom: 165%;
 }
 .noUi-vertical .noUi-tooltip {
   -webkit-transform: translate(0, -50%);
   transform: translate(0, -50%);
   top: 50%;
   right: 165%;
 }
 .noUi-horizontal .noUi-origin > .noUi-tooltip {
   -webkit-transform: translate(50%, 0);
   transform: translate(50%, 0);
   left: auto;
   bottom: 10px;
 }
 .noUi-vertical .noUi-origin > .noUi-tooltip {
   -webkit-transform: translate(0, -18px);
   transform: translate(0, -18px);
   top: auto;
   right: 28px;
 }
 @each $color, $value in $theme-colors {
  .range-slider-#{$color} {
    .noUi-handle {
      border: 2px solid $value;
      @include border-radius(100%);
      background: $white;
      cursor: default;
      transition: box-shadow 200ms;
    }
    .noUi-connect {
      background: $value;
    }
    .noUi-active {
      border-color: darken($value, 15%);
      box-shadow: 0 0.46875rem 2.1875rem rgba($value, .8),
      0 0.125rem 0.5875rem rgba($value, .9);
    }
  }
}