@import "variables";

$size: 13px;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-size: $size;
}

.DateInput_input, .DateInput_input_1 {
  &::placeholder {
    font-size: $size !important;
  }

  font-size: $size;
}

.DateRangePickerInput_arrow_svg {
  width: 15px !important;
  height: 15px !important;
}

.card-footer {
  background-color: white !important;
}

//.css-yk16xz-control,
//.css-1pahdxg-control,
//.css-1fhf3k1-control,
.react-select,
.form-control,
.input-group-text {
  min-height: 38px !important;

  * {
    font-size: 0.88rem !important;
  }

  &__indicator {
    //  position: relative;
    //  width: 30px;
    //
    //  .css-tj5bde-Svg {
    //    position: absolute;
    //    top: 6px;
    //    left: 4px;
    //  }
    //
    @extend .react-select;
  }

  &__control {
    @extend .react-select;
  }
}

.DateInput {
  * {
    font-size: $size;
  }

  .DateInput_input {
    font-size: $size;
  }
}


.progress {
  &-circle {
    transition: background .5s;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
    background: $secondary !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: white;
    cursor: pointer;
    opacity: 10 !important;
    border: 10px solid white;
  }

  &-active {
    background: $primary !important;
  }

  &-circle:hover {
    background: $info;
  }

  &-percentage {
    position: absolute;
    top: -10px;
    right: -10px;
  }

  &-line {
    left: 2.5%;
    width: 95%;
    border: 2px solid #e7e7e7;
    position: absolute;
    z-index: 0;
    margin-top: 5px;
  }

}


th {
  transition: all 0.3s ease-in-out;
}

.dragged-column {
  transform: translate3d(0, 0, 0);
}

.over-column {
  transform: translate3d(0, 0, 0);
}

/* Dropdown container */


/* Dropdown menu */
.menu {
  text-transform: capitalize;
  min-width: 300px;
  letter-spacing: 0;
  padding: 0;
  margin: 0;
  display: none;
  //position: absolute;
  //top: 100%;
  //left: 0;
  text-align: start !important;
  font-weight: normal !important;
  background-color: #fff;
  border: 1px solid #ccc;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

/* Show the dropdown menu when open */
.menu.show {
  display: block;
}

///* Menu item with sub-menu */
//.menu-item.has-children .menu-toggle::after {
//  content: "▶"; /* Add a right arrow indicator */
//  margin-left: 5px;
//}
//
///* Sub-menu */
.sub-menu {
  min-width: 250px;
  padding: 0;
  margin: 0;
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
}
//
///* Show the sub-menu when open */
.menu-item.has-children .menu-toggle.show + .sub-menu {
  display: block;
}
//
///* Style for sub-menu items */
.sub-menu-item {
  padding: 10px;
  text-decoration: none;
  color: #333;
  display: block;
}
//
///* Hover effect for sub-menu items */
.sub-menu-item:hover {
  background-color: #f2f2f2;
}

.items-groups-count {
  background-color: white;
  border-left: 2px solid rgb(0, 190, 0);
  display: flex;
  gap: 10px;
  border-radius: 4px;
  position: fixed;
  bottom: 20px;
  right: 10px;
  padding: 10px 20px;
  box-shadow: 1px 0px 9px #a2a2a2;
}

.footer-group-count {
  display: flex;
  gap: 10px;
  align-items: center;
}
