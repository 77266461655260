.collapse-list {
  overflow: hidden;
  transition: 0.4s max-height !important;

}

.collapse-list > ul {
  overflow: auto;
}

.menu-item-active {
  &:before {
    content: '';
    height: 100%;
    opacity: 1;
    width: 3px;
    background: #e0f3ff;
    position: absolute;
    left: 20px;
    top: 0;
    border-radius: 15px;
  }
}
