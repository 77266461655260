@import "variables";

.react-autosuggest__container {
  position: relative;
  color: black;
  width: 100%;
}

.react-autosuggest__input--focused {
  outline: none;
}

//.react-autosuggest__input--open {
//  border-bottom-left-radius: 0;
//  border-bottom-right-radius: 0;
//}

.react-autosuggest__suggestions-container {
  display: none;
}

.shadow-sm-custom {
  -webkit-box-shadow: 0 0.125rem 0.25rem $gray !important;
  box-shadow: 0 0.125rem 0.25rem $gray !important;
}

.react-autosuggest__suggestions-container--open {
  @extend .shadow-sm-custom;
  display: block;
  position: absolute;
  top: 51px;
  max-height: 80vh;
  overflow-y: auto;
  width: 280px;
  border: 1px solid #eaeaea !important;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 15px;
  border-radius: 10px;
  z-index: 2;
  line-height: 1;
  @media (max-width: 768px) {
    & {
      position: fixed;
      z-index: 1025;
      top: 20%;
      width: 90%;
      left: 5%;
    }
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 10px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
