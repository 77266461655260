// Fontawesome

// $fa-font-path: "../../../../vendors/@fortawesome/fontawesome-free/webfonts";

// @import "../../../../vendors/@fortawesome/fontawesome-free/scss/variables";


// @import "../../../../vendors/@fortawesome/fontawesome-free/scss/solid";

// @import "../../../../vendors/@fortawesome/fontawesome-free/scss/mixins";
// @import "../../../../vendors/@fortawesome/fontawesome-free/scss/core";
// @import "../../../../vendors/@fortawesome/fontawesome-free/scss/larger";
// @import "../../../../vendors/@fortawesome/fontawesome-free/scss/fixed-width";
// @import "../../../../vendors/@fortawesome/fontawesome-free/scss/list";
// @import "../../../../vendors/@fortawesome/fontawesome-free/scss/bordered-pulled";
// @import "../../../../vendors/@fortawesome/fontawesome-free/scss/animated";
// @import "../../../../vendors/@fortawesome/fontawesome-free/scss/rotated-flipped";
// @import "../../../../vendors/@fortawesome/fontawesome-free/scss/stacked";
// @import "../../../../vendors/@fortawesome/fontawesome-free/scss/icons";
// @import "../../../../vendors/@fortawesome/fontawesome-free/scss/screen-reader";

.fa-2x {
  line-height: 2;
}