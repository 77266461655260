// Toogles

.btn-group-xs > .btn, .btn-xs {
  padding: .35rem .4rem .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

.checkbox label .toggle, .checkbox-inline .toggle {
  margin-left: -20px;
  margin-right: 5px;
}

.toggle {
  position: relative;
  overflow: hidden;
  border-color: rgba(0, 0, 0, .2); /* bootstrap-4 - add a border so toggle is delineated */
}

.toggle input[type="checkbox"] {
  display: none;
}

.toggle-group {
  position: absolute;
  width: 200%;
  top: 0;
  bottom: 0;
  left: 0;
  transition: left 0.35s;
  -webkit-transition: left 0.35s;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.toggle.off .toggle-group {
  left: -100%;
}

.toggle-on {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.toggle-off {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none; /* Bootstrap 4.0 Support via (Issue #186)[https://github.com/minhur/bootstrap-toggle/issues/186]) */
}

.toggle-handle {
  position: relative;
  margin: 0 auto;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%;
  width: 0px;
  border-width: 0;
  background-color: #fff;
}

.toggle.btn {
  min-width: 59px;
  min-height: 34px;
}

.toggle-on.btn {
  padding-right: 24px;
}

.toggle-off.btn {
  padding-left: 24px;
}

.toggle.btn-lg {
  min-width: 79px;
  min-height: 45px;
}

.toggle-on.btn-lg {
  padding-right: 31px;
}

.toggle-off.btn-lg {
  padding-left: 31px;
}

.toggle-handle.btn-lg {
  width: 40px;
}

.toggle.btn-sm {
  min-width: 50px;
  min-height: 30px;
}

.toggle-on.btn-sm {
  padding-right: 20px;
}

.toggle-off.btn-sm {
  padding-left: 20px;
}

.toggle.btn-xs {
  min-width: 35px;
  min-height: 22px;
}

.toggle-on.btn-xs {
  padding-right: 12px;
}

.toggle-off.btn-xs {
  padding-left: 12px;
}
